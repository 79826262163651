import React from 'react'
import styled from 'styled-components'

export const BodyWrapper = styled.div`
  position: relative;
  max-width: 420px;
  width: 100%;
  background: linear-gradient(126.59deg, rgba(77, 77, 77, 0.4) 12.11%, rgba(139, 139, 139, 0.229) 46.86%, rgba(187, 187, 187, 0.1) 73.08%);
box-shadow:0px 0px 19px rgba(255, 82, 151, 1), 0px 0px 12px rgba(131, 19, 203, 1), 0px 0px 11px rgba(0, 255, 209, 1);
backdrop-filter: blur(12.2538px);
/* Note: backdrop-filter has minimal browser support */
border-radius: 24.5077px;
  padding: 1rem;
`

/**
 * The styled container element that wraps the content of most pages and the tabs.
 */
export default function AppBody({ children }: { children: React.ReactNode }) {
  return <BodyWrapper>{children}</BodyWrapper>
}
